var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          ref: "formCreateClass",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitPostsBlog.apply(null, arguments)
            },
          },
        },
        [
          _vm._l(_vm.areas, function (item, i) {
            return _c("div", { key: i, staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-8" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.title,
                            expression: "item.title",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "Título da área", type: "text" },
                        domProps: { value: item.title },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(item, "title", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "col-md-2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.color,
                            expression: "item.color",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "Cor: ex.: #000", type: "text" },
                        domProps: { value: item.color },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(item, "color", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-2" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(234, 84, 85, 0.15)",
                                expression: "'rgba(234, 84, 85, 0.15)'",
                                modifiers: { 400: true },
                              },
                            ],
                            attrs: { block: "", variant: "outline-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.repeateAgain(i)
                              },
                            },
                          },
                          [
                            _c("feather-icon", { attrs: { icon: "PlusIcon" } }),
                            _vm._v(" Post "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("p", { staticClass: "mt-2" }, [
                    _vm._v(
                      "Digite o título dos posts cadastrados para serem exibidos na home, conforme o título da área cadastrd no campo acima."
                    ),
                  ]),
                  _vm._l(item.posts, function (item, index) {
                    return _c("div", { key: index, staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-11" },
                        [
                          _c(
                            "v-select",
                            {
                              attrs: {
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o título do post",
                                options: _vm.optionsPosts,
                              },
                              on: {
                                search: _vm.fetchPosts,
                                input: function ($event) {
                                  return _vm.toSelectPost($event, i, index)
                                },
                              },
                              model: {
                                value: item.post,
                                callback: function ($$v) {
                                  _vm.$set(item, "post", $$v)
                                },
                                expression: "item.post",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-1" },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple.400",
                                  value: "rgba(234, 84, 85, 0.15)",
                                  expression: "'rgba(234, 84, 85, 0.15)'",
                                  modifiers: { 400: true },
                                },
                              ],
                              attrs: { block: "", variant: "outline-danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeItem(i, index)
                                },
                              },
                            },
                            [_c("feather-icon", { attrs: { icon: "XIcon" } })],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-md-12" }, [_c("hr")]),
                    ])
                  }),
                ],
                2
              ),
            ])
          }),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: { variant: "primary" },
              on: { click: _vm.repeateAgainArea },
            },
            [
              _c("feather-icon", {
                staticClass: "mr-25",
                attrs: { icon: "PlusIcon" },
              }),
              _c("span", [_vm._v("Nova área")]),
            ],
            1
          ),
          _c("div", { staticClass: "row justify-content-right" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(255, 255, 255, 0.15)",
                        expression: "'rgba(255, 255, 255, 0.15)'",
                        modifiers: { 400: true },
                      },
                    ],
                    staticClass: "btn-block",
                    attrs: {
                      variant: "success",
                      type: "submit",
                      disabled: _vm.submited,
                      block: "",
                    },
                  },
                  [
                    _vm.submited
                      ? _c(
                          "div",
                          [
                            _c("b-spinner", {
                              staticClass: "left",
                              attrs: { small: "", variant: "light" },
                            }),
                            _vm._v(" aguarde... "),
                          ],
                          1
                        )
                      : _c("div", [_vm._v("Cadastrar")]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }