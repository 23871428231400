<template>
  <div>
    <b-form ref="formCreateClass" @submit.prevent="submitPostsBlog">
      <div
        class="card"
        v-for="(item, i) in areas"
        :key="i"
      >
        <div class="card-body">
          <div class="form-row">
            <div class="col-md-8">
              <input v-model="item.title" placeholder="Título da área" type="text" class="form-control" />
            </div>
            <div class="col-md-2">
              <input v-model="item.color" placeholder="Cor: ex.: #000" type="text" class="form-control" />
            </div>
            <div class="col-md-2">
              <b-button
                block
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-primary"
                @click="repeateAgain(i)"
              >
                <feather-icon
                  icon="PlusIcon"
                />
                Post
              </b-button>
            </div>
          </div>
          <p class="mt-2">Digite o título dos posts cadastrados para serem exibidos na home, conforme o título da área cadastrd no campo acima.</p>
          <div class="form-row"
            v-for="(item, index) in item.posts"
            :key="index"
          >
            <div class="col-md-11">
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.post"
                placeholder="Digite o título do post"
                :options="optionsPosts"
                @search="fetchPosts"
                @input="toSelectPost($event, i, index)"
              >
                <span
                  slot="no-options"
                >
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>

            <div class="col-md-1">
              <b-button
                block
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="removeItem(i, index)"
              >
                <feather-icon
                  icon="XIcon"
                />
              </b-button>
            </div>

            <div class="col-md-12">
              <hr>
            </div>
          </div>
        </div>
      </div>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgainArea"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Nova área</span>
      </b-button>

      <div class="row justify-content-right">
        <div class="col-md-3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            type="submit"
            :disabled="submited"
            class="btn-block"
            block
          >
            <div v-if="submited">
              <b-spinner small variant="light" class="left" /> aguarde...
            </div>
            <div v-else>Cadastrar</div>
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      submited: false,
      areas: [
        {
          title: '',
          posts: []
        }
      ],
      postSelected: '',
      optionsPosts: [],
    }
  },
  created() {
    // this.getData()
  },
  methods: {
    getData() {
      this.$store
        .dispatch("Post/home")
        .then((res) => {
          this.areas = res.areas
        })
        .catch(() => {
          this.notifyDefault("error");
        })
        .finally(() => {
          this.submitedFind = false;
        });
    },
    submitPostsBlog() {
      const posts = JSON.stringify(this.areas);

      this.$store
        .dispatch("Post/postsHome", { posts: posts})
        .then(() => {
          this.notifyDefault('success');
        })
        .catch(() => {
          this.notifyDefault("error");
        })
        .finally(() => {
          this.submitedFind = false;
        });
    },
    toSelectPost(event, areaId, postId) {
      this.areas[areaId].posts[postId] = {
        code: event.code
      }
    },
    async fetchPosts(term) {
      if (term.length > 2) {
        this.$store
          .dispatch("Post/search", term)
          .then((res) => {
            this.optionsPosts = res;
          });
      }
    },
    repeateAgainArea() {
      this.areas.push({ title: '', posts: []})
    },
    repeateAgain(areaId) {
      this.areas[areaId].posts.push({ code: '', title: '' })
    },
    removeItem(areaId, postId) {
      console.log(areaId, postId)
      this.areas[areaId].posts[postId].splice(0, postId)
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
 